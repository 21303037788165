@import "~bootstrap/scss/utilities";

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    /* Create the border classes for this infix */
    .border#{$infix}-top {
      border-top-style: solid !important;
      border-top-width: $border-width !important;
    }
    .border#{$infix}-right {
      border-right-style: solid !important;
      border-right-width: $border-width !important;
    }
    .border#{$infix}-bottom {
      border-bottom-style: solid !important;
      border-bottom-width: $border-width !important;
    }
    .border#{$infix}-left {
      border-left-style: solid !important;
      border-left-width: $border-width !important;
    }

    .border#{$infix}-top-0 {
      border-top: 0 !important;
    }
    .border#{$infix}-right-0 {
      border-right: 0 !important;
    }
    .border#{$infix}-bottom-0 {
      border-bottom: 0 !important;
    }
    .border#{$infix}-left-0 {
      border-left: 0 !important;
    }

    .border#{$infix}-x {
      border-left-style: solid !important;
      border-left-width: $border-width !important;
      border-right-style: solid !important;
      border-right-width: $border-width !important;
    }

    .border#{$infix}-y {
      border-top-style: solid !important;
      border-top-width: $border-width !important;
      border-bottom-style: solid !important;
      border-bottom-width: $border-width !important;
    }

    /* Create the width classes for this infix */
    @each $size, $length in $sizes {
      .w#{$infix}-#{$size} {
        width: $length !important;
      }
    }
  }
}

.cursor-pointer {
  cursor : pointer;
}

@import "~bootstrap/scss/forms";

.form-group {
  .form-label {
    color: $label-color;
  }
  .form-control::placeholder {
    color: $input-placeholder-color;
    font-style: $input-placeholder-font-style;
    font-weight: $input-placeholder-font-weight;
    font-family: $input-placeholder-font-family;
  }
}

// prevents numeric fields from having the up and down arrows
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*
  Octane Colors
 */

// Accent / Brand Colors
$ride-octane-blue: #2366B1;
$ride-octane-ice: #F3F8FE;
$ride-octane-green: #38AF8D;
$ride-octane-black: #111111;
$ride-octane-polaris-blue: #004e97;
$ride-octane-slingshot-black: #000000;
$ride-octane-slingshot-blue-hover: #5AB8F3;
$ride-octane-indian-red: #862633;
$ride-octane-indian-red-hover: #B94151;
$ride-octane-camping-world-blue: #00669E;

// should include colors only applicable to Octane
$brand-colors: (
  'ride-octane-ice': $ride-octane-ice,
  'ride-octane-blue': $ride-octane-blue,
  'ride-octane-green': $ride-octane-green,
);

// should include all theme colors
$accent-colors: (
  'ride-octane-blue': $ride-octane-blue,
  'ride-octane-black': $ride-octane-black,
  'ride-octane-polaris-blue': $ride-octane-polaris-blue,
  'ride-octane-slingshot-black': $ride-octane-slingshot-black,
  'ride-octane-indian-red': $ride-octane-indian-red,
  'ride-octane-camping-world-blue': $ride-octane-camping-world-blue,
);

// Monochrome Colors
$white: #FFFFFF;
$moon-grey: #F8F8FA;
$smoke: #E6E9EF;
$concrete: #D5D9E0;
$steel: #B0B6C1;
$graphite: #898F99;
$asphalt: #565B66;
$raven-sub: #30333A;
$jetline-black: #01040B;

$monochrome-colors: (
  'white': $white,
  'moon-grey': $moon-grey,
  'smoke': $smoke,
  'concrete': $concrete,
  'steel': $steel,
  'graphite': $graphite,
  'asphalt': $asphalt,
  'raven-sub': $raven-sub,
  'jetline-black': $jetline-black,
);

// Alert/Contextual Colors
$light: $ride-octane-ice;
$dark: $jetline-black;
$primary: $ride-octane-blue;
$primary-dark: darken($primary, 15%);
$primary-light: transparentize($primary, .85);
$secondary: $asphalt;
$secondary-dark: darken($secondary, 15%);
$secondary-light: transparentize($secondary, .85);
$success: $ride-octane-green;
$success-dark: darken($success, 15%);
$success-light: transparentize($success, .85);
$note: #FFAE64;
$note-dark: darken($note, 15%);
$note-light: transparentize($note, .85);
$error: #D52116;
$error-dark: darken($error, 15%);
$error-light: transparentize($error, .85);
$spotlight: #C5D5EA;
$spotlight-dark: darken($spotlight, 15%);
$spotlight-light: transparentize($spotlight, .85);

// these will generate spark-text-{variant} classes
// these will generate spark-text-links-{variant} classes
$text-colors: (
  'dark': $dark,
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'note': $note,
  'error': $error,
  'spotlight': $spotlight,
  'white': $white,
  'white-50': transparentize($white, .5),
);

// these will generate spark-btn-{variant} classes
// these will generate spark-bg-{variant} classes
// these will generate spark-border-{variant} classes
$base-contextual-colors: (
  'dark': $dark,
  'light': $light,
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'note': $note,
  'error': $error,
  'spotlight': $spotlight,
);

// these will generate spark-bg-{variant} classes
// these will generate spark-border-{variant} classes
$contextual-colors: map-merge(
  $base-contextual-colors,
  (
    'primary-dark': $primary-dark,
    'primary-light': $primary-light,
    'secondary-dark': $secondary-dark,
    'secondary-light': $secondary-light,
    'success-dark': $success-dark,
    'success-light': $success-light,
    'note-dark': $note-dark,
    'note-light': $note-light,
    'error-dark': $error-dark,
    'error-light': $error-light,
    'spotlight-dark': $spotlight-dark,
    'spotlight-light': $spotlight-light,
    'white': $white,
    'moon-grey': $moon-grey,
  )
);

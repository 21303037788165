@import "~bootstrap/scss/buttons";

.btn-ol-list-dropdown {
  background-color: $input-bg;
  border: 1px solid $input-border-color;

  color: $input-color;
  font-size: $input-font-size;
  font-weight: $input-font-weight;

  .list-dropdown-placeholder {
    color: $input-placeholder-color;
    font-style: $input-placeholder-font-style;
    font-weight: $input-placeholder-font-weight;
    font-family: $input-placeholder-font-family;
  }

  &:disabled {
    opacity: 1;
    color: $input-placeholder-color;
    background-color: $input-disabled-bg;
  }
  &:hover:not(:disabled) {
    background-color: $input-bg;
    color: $input-placeholder-color;
  }
}

a.btn:not(.btn-link) {
  text-decoration: none;
}

/*
  Custom themes
 */

// BLACK
.html_3a49337c5724d18079602a4ca5a91626 {
  --primary: #{$ride-octane-black};
  --primary-dark: #{darken($ride-octane-black, .25)};
  --primary-light: #{transparentize($ride-octane-black, .85)};
}

// POLARIS_BLUE
.html_8a6c53818dc819ec971b6031d49e9373 {
  --primary: #{$ride-octane-polaris-blue};
  --primary-dark: #{darken($ride-octane-polaris-blue, .25)};
  --primary-light: #{transparentize($ride-octane-polaris-blue, .85)};
}

// SLINGSHOT_BLACK
.html_c9d8f0a1ac49790e7e87d9e3fee85877 {
  --primary: #{$ride-octane-slingshot-black};
  --primary-dark: #{$ride-octane-slingshot-blue-hover};
  --primary-light: #{transparentize($ride-octane-slingshot-black, .85)};
}

// INDIAN_RED
.html_8e150fc012f9d2eb7b61e16abca9462a {
  --primary: #{$ride-octane-indian-red};
  --primary-dark: #{$ride-octane-indian-red-hover};
  --primary-light: #{transparentize($ride-octane-indian-red, .85)};
}

// CAMPING_WORLD_BLUE
.html_70ef967be58c3d1c5981f43a42c09037 {
  --primary: #{$ride-octane-camping-world-blue};
  --primary-dark: #{darken($ride-octane-camping-world-blue, .25)};
  --primary-light: #{transparentize($ride-octane-camping-world-blue, .85)};
}

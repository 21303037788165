@import "~bootstrap/scss/dropdown";

.dropdown a {
    text-decoration: none;
}

.dropdown-menu {
    overflow: auto;
    border-radius: 5px;
    z-index: 1; // Prevents the menus from going above other elements like our sticky header and footer

    // responsive expanded dropdown menu height based on if we have room
    max-height: 300px; // displays 5 items
    @media (min-width: 719px) and (max-height: 840px) {
        max-height: 180px; // when window is small - displays 3 items
    }
}

.dropdown-item.active {
    background-color: transparent;
}

// Spark Design System Styles
// https://www.figma.com/file/zThoxvyT3zNKD8Oh5HyrUf/Spark-Design-System--1.1

@mixin create-spark-text-variant($theme-name) {
  .spark-text-#{$theme-name} {
    color: var(--#{$theme-name});
  }
}

@mixin create-spark-text-links-variant($theme-name) {
  .spark-text-links-#{$theme-name} a {
    @extend .spark-text-#{$theme-name};
    &:hover {
      opacity: .8;
    }
  }
}

@mixin create-spark-bg-variant($theme-name) {
  .spark-bg-#{$theme-name} {
    background-color: var(--#{$theme-name});
  }
}

@mixin create-spark-border-variant($theme-name) {
  .spark-border-#{$theme-name} {
    border-color: var(--#{$theme-name});
  }
}

@mixin create-spark-btn-variant($theme-name) {
  .btn-#{$theme-name},
  .spark-btn-#{$theme-name} {
    border-color: var(--#{$theme-name});
    background-color: var(--#{$theme-name});

    &.disabled,
    &:disabled {
      border-color: var(--#{$theme-name});
      background-color: var(--#{$theme-name});
    }

    &:not(:disabled):not(.disabled) {
      @include hover-focus-active {
        border-color: var(--#{$theme-name}-dark);
        background-color: var(--#{$theme-name}-dark);
      }
    }
  }
}

// Generates classes for each of the base theme colors
// $base-theme-colors = (primary, secondary...)
@each $theme-name, $color-value in $base-contextual-colors {
  // e.g. ".spark-btn-primary"
  // colors button elements
  @include create-spark-btn-variant($theme-name);
}

@each $theme-name, $color-value in $text-colors {
  // e.g. ".spark-text-primary"
  // colors text within an element
  @include create-spark-text-variant($theme-name);

  // e.g. ".spark-text-links-primary"
  // colors all links within an element
  @include create-spark-text-links-variant($theme-name);
}

// Generates classes for each of the base theme colors
// $base-theme-colors = (primary, secondary...)
@each $theme-name, $color-value in $contextual-colors {
  // e.g. ".spark-border-primary"
  // colors border of an element
  @include create-spark-border-variant($theme-name);

  // e.g. ".spark-bg-primary"
  // colors background of an element
  @include create-spark-bg-variant($theme-name);
}

$spark-text-font-classes: (
  "spark-text-lato": "Lato",
  "spark-text-karla": "Karla",
  "spark-text-montserrat": "Montserrat",
);

// Generates text font classes
// .spark-text-lato       (our form placeholder font)
// .spark-text-karla      (our default font)
// .spark-text-montserrat
@each $font-class-name, $font-value in $spark-text-font-classes {
  .#{$font-class-name} {
    font-family: $font-value;
  }
}

$spark-text-size-classes: (
  "spark-text-48px": 48px,
  "spark-text-36px": 36px,
  "spark-text-32px": 32px,
  "spark-text-24px": 24px,
  "spark-text-20px": 20px,
  "spark-text-16px": 16px,
  "spark-text-14px": 14px,
  "spark-text-12px": 12px,
  "spark-text-10px": 10px,
);

// Generates text size classes
// .spark-text-48px .spark-text-48px-bold
// .spark-text-36px .spark-text-36px-bold
// .spark-text-32px .spark-text-32px-bold
// .spark-text-24px .spark-text-24px-bold
// .spark-text-20px .spark-text-20px-bold
// .spark-text-16px .spark-text-16px-bold
// .spark-text-14px .spark-text-14px-bold
// .spark-text-12px .spark-text-12px-bold
// .spark-text-10px .spark-text-10px-bold
@each $size-class-name, $size-value in $spark-text-size-classes {
  .#{$size-class-name} {
    font-weight: normal;
    font-size: $size-value;
  }
  .#{$size-class-name}-bold {
    font-weight: bold;
    font-size: $size-value;
  }
}

// Generates text sizes for desktop overrides (e.g. add these to change text size for desktop)
// .spark-text-48px-desk .spark-text-48px-bold-desk
// .spark-text-36px-desk .spark-text-36px-bold-desk
// .spark-text-32px-desk .spark-text-32px-bold-desk
// .spark-text-24px-desk .spark-text-24px-bold-desk
// .spark-text-20px-desk .spark-text-20px-bold-desk
// .spark-text-16px-desk .spark-text-16px-bold-desk
// .spark-text-14px-desk .spark-text-14px-bold-desk
// .spark-text-12px-desk .spark-text-12px-bold-desk
// .spark-text-10px-desk .spark-text-10px-bold-desk
@include media-breakpoint-up(md) {
  @each $size-class-name, $size-value in $spark-text-size-classes {
    .#{$size-class-name}-desk {
      font-weight: normal;
      font-size: $size-value;
    }
    .#{$size-class-name}-bold-desk {
      font-weight: bold;
      font-size: $size-value;
    }
  }
}

$spark-text-style-classes: (
  "spark-text-spaced": (
    "letter-spacing": 1px,
    "text-transform": uppercase,
  ),
  "spark-text-underlined": (
    "text-decoration": underline,
  ),
  "spark-text-italic": (
    "font-style": italic,
  ),
  "spark-text-upper": (
    "text-transform": uppercase,
  ),
  "spark-text-disclosure": (
    "line-height": 22px,
    "extend": (
      "spark-text-12px",
      "spark-text-secondary",
      "spark-text-links-primary",
    ),
  ),
);

// Generates text style classes
// .spark-text-spaced
// .spark-text-underlined
// .spark-text-italic
// .spark-text-upper
// .spark-text-disclosure
@each $style-class-name, $style-values in $spark-text-style-classes {
  .#{$style-class-name} {
    @each $key, $value in $style-values {
      @if $key == "extend" {
        @each $extended-class in $value {
          @extend .#{$extended-class} !optional;
        }
      } @else {
        #{$key}: $value;
      }
    }
  }
}

$spark-box-shadow-classes: (
  "spark-shadow-element": 0px 3px 4px rgba(0, 0, 0, 0.15),
  "spark-shadow-page-layer": 0px 3px 16px rgba(0, 0, 0, 0.2),
  "spark-shadow-modal": 0px 6px 32px rgba(0, 0, 0, 0.25),
  "spark-shadow-footer": 0px -2px 4px rgba(0, 0, 0, 0.1),
  "spark-shadow-tile": 0px 0px 3px rgba(0, 0, 0, 0.25),
);

// Generates box shadow classes
// .spark-shadow-element
// .spark-shadow-page-layer
// .spark-shadow-modal
// .spark-shadow-footer
// .spark-shadow-tile
@each $box-shadow-class-name, $box-shadow-value in $spark-box-shadow-classes {
  .#{$box-shadow-class-name} {
    box-shadow: $box-shadow-value;
  }
}
